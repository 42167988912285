.search-thumbnail {
    display: block;
    background-size: cover;
    height: 30px;
    width: 30px;
}
.searchfield {
    position: absolute;
    display: block;
    width: 50%;
    max-width: calc( 1300px - 100px);
    z-index: 0;
    margin: 0;
    top: 0;
    left: auto;
    right: 100px;
    bottom: 0;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transform: translateX(1rem);
    transition: visibility 0s linear .25s,opacity .25s cubic-bezier(.22,.61,.36,1),transform .25s cubic-bezier(.22,.61,.36,1);
}
.searchfield.open {
    opacity: 1;
    visibility: visible;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    z-index: 1;
}

.form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    color: $dark;
    background: white;
    background-image: none;
    border: none;
    border-radius: 4px;
    &::placeholder {
        color: $dark;
    }
    &:focus {
        box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(80, 80, 80, 0.53);
    }
}
#searchform {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 !important;
}
.search-group {
    width: 100%;
}
.search-results {
    position: absolute;
    width: 100%;
    max-height: 85vh;
    display: block;
    color: $reverse;
    box-shadow: 0px 3px 4px #4443404d;
    background: $reverse;
    overflow-y: auto;
}
.search-item {
    padding: 5px;
    background: $reverse;
    text-align: left;
    &:hover {
        background: #f4bc57;
        // span {
        //     color: $reverse;
        // }
    }
    a {
        display: flex;
        align-items: center;
    }
    span {
        margin-right: 5px;
        font-size: 18px;
        color: $dark;
    }
}
