@font-face {
  font-family: 'fontello';
  src: url('../../font/fontello.eot');
  src: url('../../font/fontello.eot#iefix') format('embedded-opentype'),
       url('../../font/fontello.woff2') format('woff2'),
       url('../../font/fontello.woff') format('woff'),
       url('../../font/fontello.ttf') format('truetype'),
       url('../../font/fontello.svg#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrows-ccw:before { content: '\e810'; } /* '' */