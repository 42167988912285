@keyframes fadeIn {
  0% {
    opacity: 0;
  }


  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }


  100% {
    opacity: 0;
  }
}

@keyframes leftToRight {
  0% {
    transform: translate3d(-100%, 0, 0);
  }


  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes rightToLeft {
  0% {
    transform: translate3d(0, 0, 0);
  }


  100% {
    transform: translate3d(-100%, 0, 0);
  }
}