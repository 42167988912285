.bongo-menu-list {
  >ul {
    margin: 0;
    >li.menu-item-has-children {
      >ul {
        >li.menu-item-has-children {
          .mb-dropdown-icon {
            &:before {
              &:before {
                content: " ";
                display: table;
              }
              &:after {
                clear: both;
              }
            }
            &:hover {
              &:before {
                color: $red;
              }
            }
          }
        }
      }
      .mb-dropdown-icon {
        &:before {
          transition: all 0.4s ease;
          position: absolute;
          font-size: 28px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #909090;
          content: "+";
        }
      }
      .mb-dropdown-icon.open {
        &:before {
          content: "-";
        }
      }
    }
  }
}
.navbar-header-menu {
  width: 100%;
  position: relative;
  background: $dark;
  height: 40px;
  z-index: 100;
  display: flex;
  align-items: center;
  text-align: center;
}
// .navbar-header-menu.fixed {
//   position: fixed;
//   top: 0;
//   z-index: 21;
// }
// input[type=search] {
//   border: 1px solid;
//   border-color: #dedede;
//   box-shadow: 0px 0px 8px rgba(60, 5, 5, 0.32);
//   font-size: 15px;
//   font-weight: 500;
//   padding: 10px;
//   outline: none;
// }
.bottom-menu {
  display: inline-block;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease-out;
  a {
    font-size: 14px;
    font-weight: 500;
    color: $reverse;
    padding: 5px 20px;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.05s ease-in-out;
    line-height: 1;
  }
  ul.depth0 {
    >li {
      &:hover {
        >a {
          color: $dark;
          background: #FFBC57;
        }
      }
    }
  }
  ul.depth1 {
    >li {
      &:hover {
        >a {
          color: $reverse;
          background: $secondary-light;
        }
      }
    }
  }
  ul {
    li {
      display: block;
      padding: 0;
      list-style: none;
      position: relative;
      margin: 0;
      height: 100%;
      text-align: left;
    }
    >li {
      &:hover {
        >a {
          color: $primary-light;
          &:after {
            color: $primary-light;
          }
        }
      }
    }
  }
  .sub-menu {
    >li {
      width: 100%;
      margin: 0;
      padding: 0;
    }
  }
}

// .widget.header_search {
//   position: absolute;
//   top: 100%;
//   display: none;
//   box-shadow: 0px 2px 5px rgba(115, 4, 4, 0.6);
//   background: $theme;
//   padding: 10px;
//   z-index: 999;
//   animation: fadeInUp 0.4s both;
// }
.sub-menu {
  display: none;
  position: absolute;
  max-width: 200px;
  min-width: 150px;
  background: $dark;
  margin: 0;
  padding: 0;
  box-shadow: 0px 2px 4px #82828282;
  a {
    color: $reverse;
  }
}
.active {
  >.sub-menu {
    display: block;
  }
}
.sub-menu.depth0 {
  >li {
    padding: 0;
  }
}
