@media screen and (max-width: 450px) {
    // main {
    //     padding-top: 90px;
    // }

    // .page-info {
    //     padding: 2.5px 7px;
    // }
    // .navigation {
    //     height: 35px;
    //     margin-top: 54px;
    // }
    // .search-results {
    //     margin-top: 43px;
    // }
    .navbar-header-menu {
        top: 90px;
    }
    .bongo-menu-list {
        padding: 5px;
        >ul {
            >li.menu-item-has-children {
                .mb-dropdown-icon {
                    height: 35px;
                }
                >ul {
                    >.menu-item-has-children {
                        .mb-dropdown-icon {
                            height: 22px;
                        }
                    }
                }
            }
        }
        ul {
            >li.menu-item-has-children {
                .mb-dropdown-icon {
                    &:before {
                        font-size: 25px;
                    }
                }
            }
        }
        ul.depth0 {
            >li.menu-item-has-children {
                .mb-dropdown-icon {
                    &:before {
                        font-size: 22px;
                    }
                }
            }
        }
        ul.depth1 {
            >li.menu-item-has-children {
                .mb-dropdown-icon {
                    &:before {
                        font-size: 20px;
                    }
                }
            }
        }
    }

    // #breadcrumbs {
    //     margin: 0;
    //     a {
    //         font-size: 10px;
    //     }
    //     .breadcrumb_last {
    //         font-size: 10px;
    //     }
    // }
    // .last-updated {
    //     span {
    //         font-size: 10px;
    //     }
    //     i {
    //         font-size: 10px;
    //         margin-right: 2.5px;
    //     }
    // }
  
    // #search-btn {
    //     padding: 7px;
    //     font-size: 14px;
    //     width: 32.5px;
    // }
    .bottom-menu {
        a {
            font-size: 16px;
        }
    }
    ul.depth0 {
        padding-left: 10px;
        a {
            font-size: 14px;
        }
    }
    ul.depth1 {
        a {
            font-size: 13px;
        }
    }
    .wp-block-image {
        .alignright {
            max-width: 125px;
        }
    }
    .post-categories {
        margin-top: 7.5px;
        margin-bottom: 5px;
    }
    .odds-table {
        td {
            min-width: 150px;
        }
    }
    .author-img {
        width: 120px;
    }
    .author-info {
        h3 {
            margin-top: 7.5px;
        }
        p {
            font-size: 11px;
            line-height: 1.3;
        }
    }
    .author-interests {
        position: relative;
        bottom: inherit;
    }

}


@media screen and (max-width: 350px) {
    // h1 {
    //     font-size: 22px;
    //     margin-bottom: 15px;
    // }
    // h2 {
    //     font-size: 20px;
    // }
    // h3 {
    //     font-size: 16px;
    // }
    // h4 {
    //     font-size: 14px;
    // }
    // p {
    //     font-size: 11px;
    // }
    // a {
    //     font-size: 11px;
    // }
    // li {
    //     font-size: 11px;
    // }
    // span {
    //     font-size: 10px;
    // }

   
    // .page-info {
    //     padding: 5px 7px;
    // }
    // #breadcrumbs {
    //     a {
    //         font-size: 9px;
    //     }
    //     .breadcrumb_last {
    //         font-size: 9px;
    //     }
    // }
    // .last-updated {
    //     span {
    //         font-size: 9px;
    //     }
    //     i {
    //         font-size: 9px;
    //     }
    // }

    .related-info {
        p {
            font-size: 13px;
        }
        span {
            font-size: 10px;
        }
    }

}