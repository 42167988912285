
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?48166492#fontello') format('svg');
  }
}
*/
 
.icon-check:before { content: '\e800'; } /* '' */
.icon-cancel:before { content: '\e801'; } /* '' */
.icon-window:before { content: '\e802'; } /* '' */
.icon-target:before { content: '\e803'; } /* '' */
.icon-search:before { content: '\e804'; } /* '' */
.icon-mobile-1:before { content: '\e805'; } /* '' */
.icon-block-1:before { content: '\e806'; } /* '' */
.icon-plus:before { content: '\e807'; } /* '' */
.icon-minus:before { content: '\e808'; } /* '' */
.icon-list:before { content: '\e809'; } /* '' */
.icon-comment:before { content: '\e80a'; } /* '' */
.icon-export:before { content: '\e80b'; } /* '' */
.icon-vcard:before { content: '\e80c'; } /* '' */
.icon-location:before { content: '\e80d'; } /* '' */
.icon-down-open-big:before { content: '\e80e'; } /* '' */
.icon-up-open-big:before { content: '\e80f'; } /* '' */

.icon-info-circled:before { content: '\e811'; } /* '' */
.icon-bell:before { content: '\e812'; } /* '' */
.icon-rocket:before { content: '\e813'; } /* '' */
.icon-hash:before { content: '\e814'; } /* '' */
.icon-cog:before { content: '\e815'; } /* '' */
.icon-clock:before { content: '\e816'; } /* '' */
.icon-heart-1:before { content: '\e817'; } /* '' */
.icon-heart-empty:before { content: '\e818'; } /* '' */
.icon-star:before { content: '\e819'; } /* '' */
.icon-star-empty:before { content: '\e81a'; } /* '' */
.icon-right-open:before { content: '\e81b'; } /* '' */
.icon-fire:before { content: '\e81c'; } /* '' */
.icon-tags:before { content: '\e81d'; } /* '' */
.icon-edit:before { content: '\e81e'; } /* '' */
.icon-gift-1:before { content: '\e81f'; } /* '' */
.icon-mail:before { content: '\e820'; } /* '' */
.icon-location-1:before { content: '\e821'; } /* '' */
.icon-lightbulb:before { content: '\e822'; } /* '' */
.icon-flash:before { content: '\e823'; } /* '' */
.icon-globe:before { content: '\e824'; } /* '' */
.icon-award:before { content: '\e825'; } /* '' */
.icon-calendar:before { content: '\e826'; } /* '' */
.icon-credit-card:before { content: '\e827'; } /* '' */
.icon-money:before { content: '\e828'; } /* '' */
.icon-tag:before { content: '\e829'; } /* '' */
.icon-comment-1:before { content: '\e82a'; } /* '' */
.icon-params:before { content: '\e82b'; } /* '' */
.icon-cog-1:before { content: '\e82c'; } /* '' */
.icon-diamond:before { content: '\e82d'; } /* '' */
.icon-right-open-outline:before { content: '\e82e'; } /* '' */
.icon-vcard-1:before { content: '\e82f'; } /* '' */
.icon-asterisk:before { content: '\e830'; } /* '' */
.icon-code:before { content: '\e831'; } /* '' */
.icon-right:before { content: '\e832'; } /* '' */
.icon-link-ext:before { content: '\f08e'; } /* '' */
.icon-angle-double-right:before { content: '\f101'; } /* '' */
.icon-quote-left:before { content: '\f10d'; } /* '' */
.icon-gamepad:before { content: '\f11b'; } /* '' */
.icon-star-half-alt:before { content: '\f123'; } /* '' */
.icon-unlink:before { content: '\f127'; } /* '' */
.icon-ok-squared:before { content: '\f14a'; } /* '' */
.icon-rupee:before { content: '\f156'; } /* '' */
.icon-youtube-play:before { content: '\f16a'; } /* '' */
.icon-instagram-1:before { content: '\f16d'; } /* '' */
.icon-right-1:before { content: '\f178'; } /* '' */
.icon-balance-scale:before { content: '\f24e'; } /* '' */
.icon-television:before { content: '\f26c'; } /* '' */
.icon-twitter-squared:before { content: '\f304'; } /* '' */
.icon-facebook-squared-1:before { content: '\f308'; } /* '' */
.icon-linkedin-squared:before { content: '\f30c'; } /* '' */