// @font-face {
//   font-family: "fallback-font";
//   line-gap-override: normal;
//   font-stretch: semi-expanded;
//   src: local('arial');
// }

$bg-color: white; 
$font: 'Rubik', system-ui;
$titlefont: 'Rubik', system-ui;

// font-family: 'Montserrat', sans-serif;
$h1: 40px;
$h2: 32px;
$h3: 26px;
$h4: 22px;
$p: 14px;
$span: 13px; 
$li: 14px;
$a: 14px;

$clr: black; 

$theme2: #046e98;
$theme3: #ca025d;
$reverse: white;
// $dark: #333333;
$dark: #171817;
$dark2: #3a3939;
$lessdark: #3e3e3e;
$theme-black: #544e4e;
$theme-black-shadow: 0.2px 1px 2px rgba(49, 49, 49, 0.3);
$border: 0.2px 1px 3px #077ca875;
$line: #2cbdf1;
// $banner: rgba(193, 67, 67, 0.85);
$banner: rgba(4, 137, 189, 0.85);

$shadow: 0.5px 1px 2px #015c80;

$shadow2: 0.5px 0.5px 1px rgba(39, 138, 105, 0.4);

$block: #fdf5f5;
$block: #fff7f2;
$block-darker: #f5eae6;
$block-hover: #f1e9e6;
$block-border: #e2e1e17d;

$orange: #ff6b7a;
$orange: #ea5d6c;

$primary: #FB8B24;
$primary-light: #FFBC57;
$primary-light2: #ffcc7f;
$primary-lightest: #f7dca2;
$primary-dark: #ea7907;
$secondary: #008AAD;
$secondary-light: #008aadbd;
$secondary-light2: #55BADF;
$secondary-dark: #005D7E;
$grey: #636763;

$box-shadow-large: 0 5px 10px rgba(40,62,81,0.05);
$box-shadow-small: 0 2px 2px rgba(40,62,81,0.05);
// $dark: #171817;

// $btn-color1: #bf344b;
$btn-color1: #880b3c;
$btn-shadow1: #ad132d;

$btn-shadow2: #d3e0d8;
$btn-shadow3: #7d0534;

$redbtn-shadow: 0.2px 1px 2px rgba(95, 25, 25, 0.37);

$green-dark: #28ad8a;
$green-dark2: #207760;
$brown: #73635d;
$red: #a5475c;
$red: #dc5967;
$red2: #884554;
$red2: #c54956;
$theme-gradient: linear-gradient(120deg, #28ad8a, #1f6f5a);
$theme-gradient2: linear-gradient(120deg, #772c3d, #a5475c);

$block: #fff7f2;
$theme: #f5a540;
$theme-darker: #ec901b;
$theme-lighter: #f5af57;
$green: #559f3d;

// 'Lato'
$size: 28px;
$weight: 500;



