.single .content {
    width: 66.66%;
    float: left;
    padding: 0;
}

aside {
    width: 33.33%;
    float: left;
    padding: 0;
    padding-left: 10px;
}

.p-hidden {
    display: none;
}
// .article-thumbnail {
//     img {
//         height: 250px;
//         object-fit: cover;
//         width: 100%;
//     }
// }
.post_date {
    span {
        font-size: 13px;
        color: #787777;
        font-weight: 500;
    }
}
.article {
    h1 {
        margin-bottom: 10px;
    }
}
.recension-title {
    margin-top: 0;
}
.big-title {
    font-size: 40px;
}
.content {
    .disclaimer-box {
        border-left: 2px solid;
        border-color: #f08b26;
        background: #f7dca2;
        color: #181918;
        border-radius: 8px;
        padding: 5px 5px 5px 10px;
        box-shadow: 0 3px 60px rgba(40,62,81,0.1);
    }
    .toggle-content-list {
        border: 1px solid;
        padding: 2.5px 5px;
        cursor: pointer;
        color: $dark;
        border-color: #d6d6d6;
        border-radius: 4px;
        display: inline-flex;
        align-items: center;
        margin-bottom: 15px;
        margin-top: 0;
    }

    li {
        a {
            font-weight: 400;
        }
        margin: 10px 0;
    }
    p {
        a {
            font-weight: 400;
            border-bottom: 0.5px solid;
            line-height: 1.3;
            strong {
                font-weight: 400;
            }
        }
    }
    ul {
        margin-bottom: 15px;
        list-style: disc;
        list-style-position: inside;
    }
    .conditions-heading {
        margin-bottom: 0;
        display: block;
        line-height: 1;
    }
    .wp-block-pullquote {
        padding: 20px 0;
    }
    blockquote {
    font-style: italic;
    position: relative;
    float: left;
    margin: 10px 10px 20px 10px;
        p {
            font-size: 15px;
            padding: 10px 15px 15px 15px;
            line-height: 1.6;
            color: $grey;
            &:before {
                content: '“';
                font-size: 105px;
                color: #005d7e61;
                line-height: 1;
                position: absolute;
                left: -15px;
                top: -15px;
                display: block;
            }

        }
        cite {
            font-weight: 500;
            font-size: 20px;
            font-style: normal;
            display: block;
            color: $secondary-light;
            padding-left: 15px;
            line-height: 1.25;
            // &::before {
            //     content: "-";
            //     font-size: 15px;
            //     line-height: 1;
            //     display: inline-block;
            //     margin-right: 5px;
            // }
            // &::after {
            //     content: "Product Lead at BestCasinoIndia";
            //     font-size: 12px;
            //     color: #636763;
            //     font-weight: 400;
            //     line-height: 1;
            //     display: block;
            // }
        }
    }

    blockquote.cpl cite::after {
        content: "Product Lead at BestCasinoIndia";
        font-size: 12px;
        color: #636763d1;
        font-weight: 400;
        line-height: 1;
        display: block;
    }
    .double-cta {
        display: flex;
        justify-content: center;
        a {
            padding: 7.5px 25px;
            background: #28ad8a;
            font-weight: 500;
            color: #fff;
            display: inline-block;
            border: none;
            font-size: 16px;
            transition: all 0.1s ease-in-out;
            margin: 0 20px;
        }
    }
}
.toggle-content-list {
    &:after {
        transition: all 0.4s ease;
        font-size: 12px;
        float: right;
        font-family: "Fontello";
        color: $primary-light;
        content: '\e808';
        margin-left: 5px;
    }
}
.toggle-content-list.collapse {
    &:after {
        content: '\e807';
    }
}
.list-heading {
    margin-bottom: 5px;
}

ol {
    margin: 0;
    padding: 0;
    list-style-position: inside;
}


.wp-block-embed {
    margin: 0;
    iframe {
        max-width: 100%;
        width: 100%;
    }
}
.wp-block-table {
    display: block;
    max-width: 86vw;
    overflow: hidden;
    width: 100%;
    overflow-x: auto;
    td {
        padding: 5px;
    }
    th {
        padding: 5px;
    }
    tbody {
        width: 100%;
        display: inline-table;
    }
}
.wp-block-image {
    img {
        height: auto;
    }
    .alignright {
        img {
            margin-bottom: 12px;
            margin-left: 12px;
        }
        padding: 5px 0 5px 10px;
        margin: 0;
    }
}
.wp-block-image.is-resized {
    display: block;
}
.faq-heading {
    text-align: center;
    margin-bottom: 2.5px;
    font-size: 24px;
    color: #4c4c4c;
}
// .cta {
//     a {
//         display: inline-block;
//         padding: 10px 35px;
//         background: $green-dark;
//         color: $reverse;
//         display: inline-block;
//         border: none;
//         font-size: 16px;
//         font-weight: 400;
//         transition: all 0.1s ease-in-out;
//         &:hover {
//             background: #207760;
//         }
//     }
// }
// .favorite-title {
//     text-align: center;
//     margin-bottom: 10px;
//     &:before {
//         font-family: "Font Awesome 5 Free";
//         content: "\f005";
//         font-size: 20px;
//         line-height: 1;
//         margin-right: 15px;
//         font-weight: 900;
//         color: $orange;
//     }
//     &:after {
//         font-family: "Font Awesome 5 Free";
//         content: "\f005";
//         font-size: 20px;
//         line-height: 1;
//         margin-left: 15px;
//         font-weight: 900;
//         color: $orange;
//     }
// }
figcaption {
    text-align: center;
    margin-top: 5px;
    a {
        text-align: center;
        font-weight: 400;
        font-size: 16px;
    }
}
.wp-block-image.mobile-image {
    figcaption {
        a {
            margin-top: 5px;
            width: auto;
            margin-left: auto;
            display: block;
            margin-right: auto;
            padding: 5px 5px;
            background: #28ad8a;
            color: #fff;
            border: none;
            transition: all 0.1s ease-in-out;
            &:hover {
                background: #207760;
                color: white;
            }
        }
    }
}
.width-100 {
    width: 100%;
    display: block;
    text-align: center;
}

.wp-block-table.is-style-stripes {
    margin: 0;
    margin-bottom: 20px;
}
.center {
    text-align: center;
}
.related-article-item {
    display: table;
    border: 1px solid;
    border-color: $block-border;
    background: $block;
    padding: 0;
    width: 100%;
    margin-bottom: 10px;
    &:last-child {
        margin-bottom: 0;
    }
    img {
        height: 100px;
        object-fit: cover;
        width: 100%;
    }
}
.related-thumbnail {
    padding: 0;
    width: 45%;
    overflow: hidden;
    float: left;
}
.related-info {
    padding: 5px 7.5px;
    height: 100px;
    width: 55%;
    overflow: hidden;
    float: left;
    .read-more-btn {
        padding: 7.5px 5px;
        display: inline-flex;
        justify-content: center;
        width: 100%;
        height: auto;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: $primary-lightest;
        transition: all 0.1s ease-out;
        span {
            font-size: 11px;
            text-transform: capitalize;
            font-weight: 400;
            line-height: 1.3;
            display: block;
            margin: 0;
            color: $reverse;
        }
        &:hover {
            background-color: $primary-light2;
            span {
                color: $reverse;
            }
        }
    }
    p {
        margin: 0;
        margin-bottom: 5px;
        font-size: 12px;
        display: block;
        line-height: 1;
        color: #333333;
        font-weight: 700;
    }
    span {
        font-size: 10px;
        display: block;
        line-height: 1.3;
        color: #484848;
        font-weight: 400;
    }
    .entry-date {
        position: absolute;
        bottom: 5px;
        right: 5px;
        font-size: 13px;
        font-weight: 400;
        line-height: 1.2;
        color: $green-dark;
        border-bottom: 1px solid;
    }
}


.news-list-container {
    float: left;
    display: table;
    position: relative;
}

.wp-block-separator.is-style-wide {
    margin: 20px 0;
    float: left;
    width: 100%;
    opacity: 0.5;
}


.content {
    .related-article-item-content {
        width: 32.5%;
        margin-left: 1.25%;
        float: left;
        padding: 0;
        display: flex;
        margin-bottom: 20px;
        position: relative;
        &:nth-child(3n+1) {
            margin-left: 0;
        }
        .item-container {
            border: 1px solid;
            border-color: #4a494914;
            width: 100%;
            box-shadow: $box-shadow-small;
        }

        .related-thumbnail {
            width: 100%;
            height: 200px;
            img {
                height: 100%;
                object-fit: cover;
                width: 100%;
            }
            
        }

        .related-info {
            width: 100%;
            height: 170px;
            padding: 15px;

            p {
                font-size: 16px;
                margin: 2.5px 0;
                margin-bottom: 12.5px;
                line-height: 1.2;
            }

            span {
                font-size: 11px;
            }

            .read-more-btn {

                span {
                    font-size: 14px;
                    text-transform: capitalize;
                    font-weight: 500;
                    color: $dark;
                }
            }
        }

    }
  .latest-news-btn {
    display: flex;
    width: 100%;
    justify-content: center;
    .latest-news-read-more {
      padding: 7.5px 5px;
      display: flex;
      justify-content: center;
      width: 100%;
      max-width: 250px;
      height: auto;
      background-color: $primary-lightest;
      transition: all 0.1s ease-out;
      span {
        font-size: 11px;
        text-transform: capitalize;
        font-weight: 400;
        line-height: 1.3;
        display: block;
        margin: 0;
        color: $reverse;
      }
      &:hover {
        background-color: $primary-light2;
        span {
          color: $reverse;
        }
      }
    }
  }
}

.childs {
    padding-left: 20px;
}

@media screen and (max-width: 991px) {
    .single .content {
        width: 100%;
    }

    aside {
        width: 100%;
    }
}



