footer {
    display: table;
    width: 100%;
    margin-top: 20px;
    background: $primary-light;
    border-top: 1px solid;
    border-color: rgba(167,167,167,0.31);
    .sidebar-footer {
        color: $dark;
        text-transform: uppercase;
        border-bottom: 1px solid;
        font-weight: 700;
        display: inline-block;
        margin-bottom: 5px;
        font-size: 19px;
        line-height: 1;
    }
    .footer-logo {
        width: auto;
        text-align: right;
    }
    .footer-info-row {
        list-style: none;
        text-align: center;
        width: auto;
        text-align: left;
        width: 23%;
        margin-left: 2%;
        &:nth-child(1) {
            margin-left: 0;
        }
        ul {
            list-style-type: disc;
            list-style-position: inside;
            // color: $reverse;
            color: $dark;
        }
        li {
            text-align: left;
        }
        a {
            color: $dark;
            font-weight: 500;
        }
        p {
            color: $dark;
        }
        .compliance-logo {
            display: flex;
            justify-content: center;
            height: 100px;
        }
      &.footer-nav {
        width: 13%;
      }
      &.footer-social {
        width: 7%;
      }
    }

  .footer-social {
    .footer-social__links {
      list-style: none;
      display: flex;
      justify-content: center;
      &-icon {
        padding: 0 5px;
        i{
          font-size: 25px;
        }
      }
    }

  }
    .menu {
        text-align: center;
    }
    .social-media {
        width: 100%;
        text-align: center;
        i {
            margin: 25px 1.5px;
            background: white;
            width: 40px;
            height: 40px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            font-size: 20px;
        }
    }
}
.footer-info-container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
    max-width: 1200px;
    flex-wrap: wrap;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    padding: 0 20px;
}
.copyright-disclaimer {
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    background: $dark;
    margin-top: 20px;
    span {
        color: $reverse;
        font-weight: 400;
        font-size: 13px;
    }
}
.compliance-logos {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    img {
        height: 50px;
    }
}
