.author-archive {
  section:nth-of-type(odd) {
    background: none;
  }

  .archive {
    padding: 0;
  }
}

.author .author-container {
  margin-top: 0;
}

.author-container {
  margin: 20px auto;
  position: relative;
  width: 100%;
  display: flex;
  float: left;
  justify-content: center;
}

.author-title {
  text-align: center;
  width: 100%;
  display: inline-block;
  font-size: 22px;
  font-weight: 300;
}

.wp-block-column .author-box {
  margin: 0;
  width: auto;
}

.author-box {
  display: flex;
  position: relative;
  width: 275px;
  margin-right: 25px;
  box-shadow: $box-shadow-large;
  background: white;
  border: 1px solid;
  border-radius: 10px;
  overflow: hidden;
  border-color: rgba(226, 225, 225, 0.490196);
  flex-direction: column;
  align-items: center;
  padding: 15px 0;

  .author-img {
    width: 200px;
    height: 200px;
  }
}

.author-title-field {
  margin-bottom: 2.5px;
}

.author-bio {
  padding: 20px;
  width: calc(100% - 275px);
  box-shadow: $box-shadow-large;
  position: relative;
  background: white;
  border: 1px solid;
  float: left;
  border-radius: 10px;
  overflow: hidden;
  border-color: rgba(226, 225, 225, 0.490196);
}

.author-info {
  padding: 5px 20px;
  text-align: center;

  h3 {
    margin-top: 10px;
  }

  p {
    line-height: inherit;
  }
}

.user-email {
  font-size: 12px;
  color: $theme-darker;
  display: block;
}

.socials-container {
  margin-top: 5px;
}

.author-socials {
  font-size: 22px;
}

.icon-linkedin-squared {
  color: #0176b5;
}

.icon-instagram-1 {
  color: #ef2d6b;
}

.icon-twitter-squared {
  color: #4fa1f2;
}

.icon-facebook-squared-1 {
  color: #4577f2;
}

.icon-youtube-play {
  color: #ed0104;
}

.author-name {
  border-bottom: 0.5px solid;
  border-color: #b9b9b9;
  margin: 5px 0;
  font-size: 18px;
  display: block;
}

.user-role {
  font-size: 14px;
  color: #333;
  display: block;
}


.content .author-interests {
  list-style: none;
  float: left;

  li {
    margin: 0;
    margin-bottom: 5px;
    line-height: 1.7;
    color: #2b2b2b;
  }
}

.author-interests li:before {
  font-family: "Fontello";
  content: '\e817';
  margin-right: 5px;
  font-weight: 900;
  color: $secondary-light;
}

.container.author-pages {
  padding: 30px 50px;
}

.content .author-pages .related-article-item-content {
  .related-info p {
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 0;
    font-weight: 400;
    text-align: center;
  }

  .related-thumbnail {
    height: 150px;
    background: $primary-light;
    img.no-thumbnail {
      object-fit: scale-down;
    }
  }

  margin-bottom: 30px;

  .related-info {
    height: 110px;
  }
}

.author-container .author-description {
  margin-bottom: 15px;
  line-height: 1.7;
  float: left;
  color: #2b2b2b;
}

.author {
  .archive section {
    border: none;
  }

  section {
    border-radius: 10px;
    border: 1px solid;
    border-color: rgba(226, 225, 225, 0.490196);
    box-shadow: $box-shadow-large;
  }

  h1 {
    margin-bottom: 5px;
  }

  h2 {
    text-align: center;
    margin-bottom: 20px;
    font-weight: 700;
    color: $secondary;
    margin-top: 0;
  }
}

.author-container strong {
  font-size: 24px;
}