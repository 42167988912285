.content {
    .wp-block-image {
        .aligncenter {
            margin-left: auto;
            margin-right: auto;
        }
    }

}
.custom-title.smaller {
    font-size: 18px;
}
.check-title {
    font-size: 20px;
    &:before {
        font-family: "Fontello";
        content: "\f14a";
        margin-right: 5px;
        color: #28ad8a;
    }
}

.review-list-columns {
    .wp-block-column {
        padding: 30px;
    }
}