@media (max-width: 991px) and (min-width: 900px) {
    .logged-in .filter-form .all-filter-options {
        top: 102px;
        height: calc( 100vh - 102px);
    }
}

@media (max-width: 900px) and (min-width: 783px) {
    .logged-in .filter-form .all-filter-options {
        top: 125px;
        height: calc( 100vh - 125px);
    }
}

@media (max-width: 782px) {
    .logged-in .filter-form .all-filter-options {
        top: 139px;
        height: calc( 100vh - 139px);
    }
}
