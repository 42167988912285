.button {
    text-transform: uppercase;
    padding: 5px 10px;
    border-radius: 8px;
    text-align: center;
}

.button.primary {
    color: $dark;
    background: $primary;
    font-weight: 500;
    &:hover {
        background: $primary-dark;
    }
}

.button.secondary {
    color: $dark;
    border: 1px solid $dark;
    font-weight: 500;
    &:hover {
        background: #f3f6fc;
    }
}
.closing-button {
    display: block;
    margin-bottom: 20px;
    cursor: pointer;
}


button {
    box-shadow: 0px 2px 10px rgba(25,1,52,0.2);
    border-radius: 8px;
    border: none;
    background: white;
    color: #171817;
    font-weight: 500;
    text-transform: uppercase;
    width: 100px;
    padding: 5px 10px;
    margin: 0 5px;
    font-size: 11px;
    cursor: pointer;
}

.btn {
    padding: 10px 5px;
    display: block;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0.5px 0.5px 1px rgba(39,138,105,0.4);
    transition: all 0.2s ease-out;
    position: relative;
    overflow: hidden;
    border: 1px solid transparent;
    text-align: center;
    margin: 10px 0;
    span {
        font-size: 18px;
        font-weight: 500;
        color: $dark;
    }
    &::after {
        background: #fff;
        content: "";
        height: 155px;
        left: -110px;
        opacity: 0.15;
        position: absolute;
        top: -50px;
        transform: rotate(35deg);
        transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
        width: 80px;
        z-index: 1;
    }
    &:hover {
        opacity: 0.8;
        &::after {
            left: 120%;
            transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
        }
    }
}
.cta.btn {
    background-color: $primary;

}
.internal.btn {
    border: 1px solid;
    border-color: $dark;
}
.btn.custom-btn {
    width: max-content;
    margin: 10px auto;
    padding: 10px 45px;
}
.btn.custom-btn.full {
    width: 100%;
}