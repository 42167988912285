.no-margin {
    margin-bottom: 0;
}
.alignright {
    display: inline;
    float: right;
    margin-left: 1.625em;
}
.aligncenter {
    clear: both;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
.wp-block-table.default-table {
    tr {
        display: table-header-group;
    }
    td {
        padding: 10px;
        &:not(:first-child) {
            width: auto;
        }
        &:first-child {
            font-weight: 600;
        }
    }
}
#read-more {
    margin-top: -10px;
    display: table;
}
.clearfix {
    &:after {
        display: table;
        content: " ";
        clear: both;
    }
    &:before {
        display: table;
        content: " ";
    }
}