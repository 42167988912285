.toggle-switch-container {
    display: table;
    margin: 10px 0;
    width: 100%;
}
.onoffswitch {
    position: relative; 
    -webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}
.onoffswitch-checkbox {
    position: absolute;
    opacity: 0;
    pointer-events: none;
}
.onoffswitch-label {
    display: block; overflow: hidden; cursor: pointer;
    border: 1px solid #D6D6D6; border-radius: 8px;
    position: relative;
    width: 65px;
    margin-right: 10px;
}
.onoffswitch-inner {
    display: block; width: 200%; margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
}
.onoffswitch-inner:before, .onoffswitch-inner:after {
    display: block; float: left; width: 50%; height: 26px; padding: 0; line-height: 26px;
    font-size: 12px; color: white; font-family: Trebuchet, Arial, sans-serif; font-weight: bold;
    box-sizing: border-box;
}
.onoffswitch-inner:before {
    content: "ON";
    padding-left: 10px;
    background-color: $primary; color: white;
}
.onoffswitch-inner:after {
    content: "OFF";
    padding-right: 10px;
    background-color: white; color: #FB8B24;
    text-align: right;
}
.onoffswitch-switch {
    display: block; width: 18px; margin: 4px;
    background: #FB8B24;
    position: absolute; top: 0; bottom: 0;
    right: 35px; border-radius: 8px;
    transition: all 0.3s ease-in 0s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label > .onoffswitch-inner {
    margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label > .onoffswitch-switch {
    right: 0px; 
    background-color: white; 
}
