@media (max-width: 1300px) {
    .logo-container {
        margin-left: 20px;
    }
}

@media (max-width: 1200px) {
    .bottom-menu {
        a {
            font-size: 13px;
            padding: 5px 12.5px;
        }
    }
}


@media (max-width: 1049px) {

    .navbar-header-menu {
        width: 100%;
        height: 0;
        position: absolute;
        border-bottom: none;
        background: none;
        z-index: 9;
        top: 70px;
    }
    .navbar-header-menu.fixed {
        position: absolute;
    }

    footer .sidebar-footer {
        border-bottom: none;
    }

    .bongo-menu-list {
        transition: all 0.4s ease;
        width: 100%;
        background: #ffffff;
        margin: 0;
        padding: 10px;
        position: absolute;
        top: 42px;
        right: 0;
        z-index: 999;
        animation: fadeInUp 0.4s both;
        box-shadow: 1px 2px 2px #27020236;
    }

    .bottom-menu ul li {
        display: block;
    }

    .bottom-menu>ul>li {
        border-bottom: 1px solid;
        border-color: rgba(136, 10, 60, 0.15);
        padding: 0;
        text-align: left;
    }

    .bottom-menu>ul>li:hover, .bottom-menu>ul>li:active{
        color: $theme;
        
        >a {
            color: $theme;
        }

        >.mb-dropdown-icon:before {
            color: $theme;
        }

    }

     .bottom-menu>ul>li:last-child {
        border-bottom: 0;
    }

    .bottom-menu a {
        font-size: 18px;
        font-weight: 400;
        color: $dark;
        display: block;
        padding: 12px 5px 12px 5px;
    }

    .bongo-menu-list > ul > li > a:after {
        color: $dark;
    }


    .bongo-menu-list > ul > li.menu-item-has-children .mb-dropdown-icon {
        position: absolute;
        top: 1px;
        right: 0;
        width: 22px;
        height: 43px;
        font-size: 14px;
        font-weight: 400;
        padding: 0;
    }

    .bongo-menu-list > ul > li.menu-item-has-children > ul > li.menu-item-has-children .mb-dropdown-icon {
        height: 37px;
    }

    .bongo-menu-list>ul>li.menu-item-has-children>ul>li.menu-item-has-children > ul > li.menu-item-has-children .mb-dropdown-icon {
        height: 34px;
    }

    .sub-menu { 
        padding: 5px 0 0 0;
    }

    .bongo-menu-list>ul>li.menu-item-has-children>ul>li.menu-item-has-children .mb-dropdown-icon:hover:before {
        color: $reverse;
    }

    .sub-menu.depth0>li {
        padding: 0;
    }

    .sub-menu.depth1>li {
        padding: 0;
        padding-left: 20px;
    }

    .sub-menu.depth2>li {
        padding: 0;
    }

    ul.depth0 {
        display: none;
        background: none;
        margin: 0;
        width: 100%;
        max-width: 100%;
        position: relative;
        box-shadow: none;
       a {       
            font-size: 16px;
            color: #5c5c5d;     
            padding-left: 20px;
        }
        >li:hover {
            >a {
                color: $reverse;
            }
            >.mb-dropdown-icon:before {
                color: $reverse;
            }
        }
    }

   .sub-menu a:active {
    background: none;
    color: $dark;
   }

    ul.depth1 {
        display: none;
        background: none;
        margin: 0;
        width: 100%;
        max-width: 100%;
        position: relative;
        padding: 5px 0;
        box-shadow: none;
        a {
            color: #767677;
            font-size: 15px;
            font-weight: 400;
            padding: 10px 0;
            padding-left: 10px;
        }
    }

    ul.depth1>li:hover {
        >a {
            color: $red;
        }
        >.mb-dropdown-icon:before {
            color: $reverse;
        }
    }

    ul.depth2 {
        display: none;
        background: none;
        margin: 0;
        width: 100%;
        position: relative;
        padding: 0;
        padding-left: 5px;
        box-shadow: none;

        a {
            color: #767677;
            font-size: 12px;
            font-weight: 400;
             padding: 5px 0;
        }
    }

    ul.depth2>li:hover {
        >a {
            color: $red;
        }
        >.mb-dropdown-icon:before {
            color: $red;
        }
    }

    ul.depth0>li.menu-item-has-children .mb-dropdown-icon:before {
        font-size: 24px;
    }

    ul.depth1>li.menu-item-has-children .mb-dropdown-icon:before {
        font-size: 22px;
    }

    // .search-results {
    //     margin-top: 13px;
    // }
    .bongo-menu-list {
        height: calc( 100vh - 55px );
        top: 0;
        overflow-y: auto;
    }
    .form-control {
        background-color: $reverse;
        color: $dark;
        &::placeholder {
            color: $dark;
        }
    }

}


@media (max-width: 991px) {

    .related-article-item {
        display: flex;
    }
    .related-thumbnail {
        width: 30%;
    }
    .related-info {
        width: 70%;
        padding: 5px;
        p {
            font-size: 16px;
        }
        span {
            font-size: 13px;
        }
    }
   
    .tc-sidebar-box {
        span {
            font-size: 11px;
        }
    }
    .footer-info-container {
        flex-direction: row;
    }
    footer {
        .footer-logo {
            width: 100%;
            text-align: center;
            img {
                width: 50px;
            }
        }
        .footer-info-row {
            width: 33.33%;
            padding: 0;
            margin-top: 10px;
            text-align: center;
            li {
                text-align: center;
            }
          &.footer-nav {
            width: inherit;
          }
          &.footer-social {
            width: inherit;
          }
          .footer-social__links {
            display: flex;
            flex-direction: row;
            justify-content: center;
            li{
              padding: 0 10px;
            }
          }
        }
    }

    .content {
        .related-article-item-content {
            .related-thumbnail {
                height: 150px;
            }
            .related-info {
                padding: 10px;
            }
        }
    }
    .post-categories {
        display: none;
    }
    ul.large {
        li {
            font-size: 14px;
        }
    }
    ul.checkmarks {
        li {
            &:before {
                margin-right: 7.5px;
            }
        }
    }
    .fullwidth {
        .schema-faq-section {
            width: 100%;
            margin: 10px 0;
        }
    }
    .container.author-pages {
        padding: 30px 20px;
    }

    .newsletter-container {
        flex-direction: column;
        .newsletter-info {
            width: 100%;
            margin-bottom: 20px;
        }
        #newsletter {
            width: 100%;
            .af-form .af-standards .af-element {
                padding: 0;
                width: 100%;
                margin-bottom: 15px;
            }
            .buttonContainer input.submit {
                width: auto;
                padding: 5px 50px;
            }
        }
    }

}


@media (max-width: 900px) {
    .navbar-header-menu {
        top: 93px;
    }
    .bongo-menu-list {
        height: calc( 100vh - 93px);
    }
    .searchfield {
        left: 0;
        right: auto;
        width: 100%;
    }
    #searchform {
        width: calc( 100% - 120px);
        padding-left: 10px;
    }
    .search-results {
        margin-top: 45px;
    }
    .search-item {
        padding: 5px 10px;
    }

}

@media screen and (max-width: 767px) {
    .content {
        blockquote {
            p {
                font-size: 18px;
            }
            margin-bottom: 10px;
            padding: 5px 10px;
        }
        .wp-block-pullquote {
            padding: 10px 0;
        }
    }
    .faq-heading {
        font-size: 20px;
    }

    .top-menu {
        a {
            color: $dark;
            font-size: 14px;
        }
    }

    .container-block {
        padding: 20px 10px;
        margin-bottom: 10px;
        box-shadow: 0 10px 20px rgba(40,62,81,0.10);
    }
    .content { 
        .related-article-item-content {
            width: 100%;
            margin: 0;
            margin-bottom: 20px;
            .related-thumbnail {
                height: 220px;
            }
            .related-info {
                display: table;
                height: 100%;
                .read-more-btn {
                    position: relative;
                    margin-top: 30px;
                }
            }
        }
    }
    .author-container {
        flex-direction: column;
        padding: 0;
    }
    .author-box {
        width: 100%;
        margin-bottom: 20px;
    }
    .author-info {
        padding: 5px 20px 15px 20px;
    }
    .author-bio {
        width: 100%;
    }
    .container.author-pages {
        padding: 20px;
    }

    section.mobile-white {
        background: white;
    }
    .container-block.mobile-no-space {
        padding: 0;
        margin-top: 0;
        background: transparent!important;
        box-shadow: none;
    }

    .content .schema-faq-question {
        font-size: 14px;
        padding: 10px;
    }
    .content .schema-faq-answer {
        padding: 15px;
    }

}


@media screen and (max-width: 650px) {
    footer {
        .footer-info-row {
            width: 100%;
        }
        .sidebar-footer {
            margin-bottom: 2.5px;
        }
    }
}

@media screen and (max-width: 600px) {

    .archive {
        padding: 0 5px;
    }
    .container.inside-article {
        padding: 0 10px;
    }
    
    .sidebar {
        padding: 0 5px;
    }
    .default-content {
        padding: 0 5px;
    }
    .related-thumbnail {
        width: 35%;
    }
    .related-info {
        width: 65%;
        padding: 2.5px 5px;
        p {
            font-size: 14px;
            margin-bottom: 1px;
        }
        span {
            font-size: 11px;
        }
    }

    .content {
        blockquote {
            padding: 5px;
            p {
                font-size: 14px;
                padding: 0;
                margin-bottom: 15px;
            }
        }
    }

    #wpadminbar {
        position: fixed;
    }
    
    .wp-block-image {
        .alignright {
            max-width: 150px;
        }
    }
    .faq-heading {
        font-size: 18px;
    }
    
    .custom-pages-link-container {
        .right-arrows {
            display: none;
        }
    }
    .wp-block-image.mobile-image {
        .alignright {
            max-width: 100%;
            width: 100%;
            padding: 0;
        }
        img {
            width: 100%;
        }
        figcaption {
            margin: 0 0 10px 0;
        }
    }
    .wp-block-table.is-style-stripes {
        padding: 2.5px;
        tr {
            font-size: 11px;
            &:first-child td {
                font-size: 12px;
            }
        }

    }

    .content blockquote cite {
        padding-left: 0;
    }

    .content blockquote.cpl cite::after {
        padding-left: 0;
    }

}