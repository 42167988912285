@media (min-width: 1050px) {
    .menu-item-has-children {
        >a {
            &:after {
                margin-left: 0.25em;
                font-size: 13px;
                vertical-align: middle;
                font-family: "Fontello";
                content: '\e80e';
                color: $reverse;
                float: right;
            }
        }
        &:hover {
            >a {
                &:after {
                    margin-left: 0.25em;
                    font-size: 13px;
                    vertical-align: middle;
                    font-family: "Fontello";
                    content: '\e80f';
                    transition: all 0.1s ease-in-out;
                    color: $reverse;
                }
            }
        }
    }
    .bottom-menu {
        ul.depth0 {
            >li {
                border-bottom: 0.5px solid;
                border-color: #dadada0d;
                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }
    .bongo-menu-list {
        >ul {
            >li.menu-item-has-children.menu-item {
                &:hover {
                    >ul {
                        display: inline-block;
                        z-index: 999;
                        animation: menuEffect 0.4s both;
                    }
                }
            }
            display: flex;
            height: 100%;
            align-items: center;
        }
    }
    ul.depth0 {
        >li.menu-item-has-children.menu-item {
            &:hover {
                >ul {
                    display: block;
                    top: 0;
                    padding: 0;
                    left: 100%;
                    margin: 0;
                    z-index: 999;
                    animation: menuEffect 0.4s both;
                }
            }
        }
        >li.menu-item-has-children {
            >a {
                &:after {
                    color: $dark;
                    content: "\f054";
                    position: absolute;
                    right: 2.5px;
                }
            }
            &:hover {
                >a {
                    &:after {
                        color: $reverse;
                        content: "\f054";
                        position: absolute;
                        right: 2.5px;
                    }
                }
            }
        }
    }
    ul.depth1 {
        >li.menu-item-has-children.menu-item {
            &:hover {
                >ul {
                    display: block;
                    top: 100%;
                    padding: 0;
                    left: 0;
                    margin: 0;
                    z-index: 999;
                    animation: menuEffect 0.4s both;
                }
            }
        }
        >li.menu-item-has-children {
            >a {
                &:after {
                    color: $dark;
                }
            }
        }
    }
    .sub-menu {
        li {
            a {
                font-size: 13px;
                font-weight: 400;
                padding: 10px 10px;
                text-transform: none;
            }
        }
    }
    .depth1 {
        li {
            a {
                font-size: 12px;
                font-weight: 400;
                padding: 10px 10px;
            }
        }
    }
    .bongo-col-menu {
        display: flex;
        justify-content: center;
        max-width: 1300px;
        margin: 0 auto;
        height: 100%;
    }
}


@media (min-width: 601px) { 

    @keyframes pulse {
        0% {
            box-shadow: 0px 0px 0px #6bd4b1;
            border-radius: 0.1px;
        }
        100% {
            box-shadow: 0 0 0 12px transparent;    
        }
    }

    // .toplist-brands .toplist-flex:hover .toplist-cta .cta-button {
    //     animation: pulse 1.25s infinite;
    //     box-shadow: 0 0 0 0 rgba(115,205,64,.7);
    // }

    .sub-featured .featured-block .featured-brand .featured-bonus-info:hover i {
        animation: pulse 1.25s infinite;
        box-shadow: 0 0 0 0 rgba(115,205,64,.7);
    }

}