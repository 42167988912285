.newsletter-container {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    max-width: 1200px;
    margin: 20px auto;
    padding: 10px 20px 20px 20px;
    border-bottom: 1px solid;
    border-color: #a5a5a57d;
}
#newsletter {
    position: relative;
    width: 50%;
    padding: 0 20px;
    .af-form {
        .af-standards {
            .af-element {
                padding-right: 10px;
                padding-left: 10px;
                float: left;
                width: 50%;
                margin-bottom: 10px;
            }
            .af-element.af-element-checkbox {
                width: 100%;
            }
        }
    }
    .buttonContainer {
        input.submit {
            width: auto;
            font-family: $font;
            -webkit-font-smoothing: antialiased;
            border: none;
            font-size: 16px;
            color: $reverse;
            cursor: pointer;
            padding: 4px 12px;
            background: $theme;
            width: 100%;
            height: 40px;
        }
    }
    .af-body {
        input.text {
            font-size: 15px;
            color: $reverse;
            font-family: $font;
            -webkit-font-smoothing: antialiased;
            padding: 2.5px 5px!important;
            background-color: #FFFFFF;
            border-color: #D9D9D9;
            border-width: 1px;
            border-style: solid;
            color: #C7C7C7;
            text-decoration: none;
            width: 100%;
            height: 40px;
        }
        .af-checkWrap {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            input.checkbox {
                height: 18px;
                width: 18px;
                margin: 0;
                margin-right: 5px;
                cursor: pointer;
            }
        }
    }
}
#af-form-1145574630 {
    .af-body {
        label.choice {
            font-size: 12px;
            color: $reverse;
        }
        input.text {
            &:focus {
                color: #333;
            }
        }
        textarea {
            &:focus {
                color: #333;
            }
        }
    }
}
.newsletter-info {
    width: 50%;
    padding: 0 20px;
    strong {
        font-size: 22px;
        width: 100%;
        float: left;
        color: $reverse;
        margin-bottom: 10px;
        font-family: $titlefont;
    }
    span {
        color: $reverse;
    }
}

