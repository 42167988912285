.content .big-list {
    list-style: none;
    li {
        font-style: italic;
        strong {
            color: $secondary;
            font-size: 17px;
        }
    }
}
.list-arrows.break-list {
    padding: 0;
    li {
        strong {
            display: inline-block;
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 5px;
        }
    }
}
ul.checkmarks {
    list-style: none;
    li {
        &:before {
            font-family: "Fontello";
            content: '\e800';
            margin-right: 5px;
            font-weight: 900;
            color: $secondary-light;
        }
    }
}
ul.large {
    li {
        font-size: 16px;
        line-height: 1.3;
    }
}

.aligned-list {
    li {
        margin: 0;
        &:before {
            font-family: "Fontello";
            content: '\e800';
            margin-right: 5px;
            font-weight: 900;
            color: $secondary-light;
        }
    }
}

.content {
    ul.aligned-list {
        display: grid;
        justify-content: center;
        list-style: none;
    }
    ul.content-list {
        display: none;
        margin-bottom: 10px;
        margin-top: 0;
        padding: 0;
        li {
            &:first-child {
                margin-top: 0;
            }
        }
        a {
            color: $dark;
        }
    }
    ul.bonus-list {
        li {
            &::before {
                content: "\f101";
                font-family: "Fontello";
                font-weight: 900;
                color: $green-dark;
                margin-right: 5px;
            }
        }
    }
    ul.list-arrows {
        li {
            &::before {
                content: "\f101";
                font-family: "Fontello";
                font-weight: 900;
                color: $secondary;
                margin-right: 5px;
            }
        }
    }
    ul.conditions {
        padding-left: 0;
        margin-top: 0;
        list-style: none;
        li {
            margin: 12.5px 0;
            font-style: italic;
            font-size: 12px;
            font-weight: 400;
            color: black;
            &::before {
                font-family: "Fontello";
                content: '\e830';
                margin-right: 2.5px;
                font-size: smaller;
                height: auto;
                color: #ea5d6c;
                line-height: 1;
                font-style: normal;
            }
        }
    }
    .bonus-list {
        padding: 7.5px 2.5px 7.5px 7.5px;
        margin-bottom: 15px;
        border-left: 8px solid #55f1be;
        box-shadow: 1px 3px 8px rgba(117, 117, 117, 0.19);
        float: left;
        width: 100%;
        background: white;
        list-style: none;
        li {
            font-size: 16px;
            margin: 7.5px 0;
        }
    }

    .heading-pros {
        border-bottom: 1px solid;
        display: inline-block;
        border-color: #28ad8a7a;
        margin-bottom: 0;
        color: $green-dark;
    }
    .heading-cons {
        border-bottom: 1px solid;
        display: inline-block;
        border-color: #dc596770;
        margin-bottom: 0;
        color: $red;
    }
    .pros-cons-list {
        margin: 0px 0;
        list-style: none;
        padding: 0;
        margin-bottom: 10px;
        float: left;
        width: 100%;
        li {
            font-size: 14px;
            font-weight: 400;
            margin: 10px 0;
            padding: 10px;
            background: white;
            color: black;
            display: flex;
            align-items: center;
            border: 0.5px solid;
            border-color: #8484841f;
            box-shadow: 1px 2px 4px rgba(117,117,117,0.19);
        }
    }
    .pros-cons-list.pros {
        li {
            &:before {
                font-family: "Fontello";
                content: '\e800';
                margin-right: 5px;
                font-size: 18px;
                color: $green-dark;
            }
        }
    }
    .pros-cons-list.cons {
        li {
            &:before {
                font-family: "Fontello";
                content: '\e801';
                margin-right: 5px;
                font-size: 18px;
                color: $red;
            }
        }
    }

    ul.arrows {
        list-style: none;
        li {
            &:before {
                font-family: "Fontello";
                content: '\f178';
                margin-right: 10px;
                font-weight: 900;
                font-size: inherit;
                color: $secondary-light;
            }
        }
    }

}